<template>
  <div class="bdInfo">
    <canvas id="the-canvas"></canvas>
    <div class="myDiv" style="min-height: 10px; border-radius: 0">审核流程</div>
    <van-steps direction="vertical" :active="active">
      <van-step
        v-for="(item, index) in data.FormAuditFlow"
        :key="item.id"
        v-if="item.fmad07 == 1"
      >
        <p v-if="item.fmad05 == 1">【会签】</p>
        <p v-else>【或签】</p>
        <h3>{{ item.fmad06 }}</h3>
        <div class="flexLR">
          <p>{{ item.name }}</p>
          <span
            v-if="item.state != ''"
            style="border: 1px solid #94c7fa; color: #94c7fa"
            >{{ item.state }}</span
          >
          <span v-else>{{ item.state }}</span>
        </div>
      </van-step>
    </van-steps>
    <div class="myDiv" style="min-height: 10px; border-radius: 0">流程记录</div>
    <van-steps
      direction="vertical"
      active-icon="arrow-down"
      active-color="#38f"
      :active="data.FormAuditRecord.length"
    >
      <van-step
        v-for="(item, index) in data.FormAuditRecord"
        :key="item.farc01"
      >
        <div class="flexLR" :style="{ color: item.farc04 == '2' ? 'red' : '' }">
          <div>
            {{ item.farc05 }}
          </div>
          <div>
            {{ item.farc03 }}
          </div>
        </div>
      </van-step>
    </van-steps>
  </div>
</template>

<script>
export default {
  name: "bdInfo",
  components: {},
  data() {
    return {
      data: {},
    };
  },
  computed: {},
  watch: {},
  methods: {
    pdfTypeImg() {
      const url = this.$store.state.baseUrl + this.data.FormApply.famp06||"";
      pdfjsLib.GlobalWorkerOptions.workerSrc = "pdf.worker.js";

      const loadingTask = pdfjsLib.getDocument(url);
      (async () => {
        const pdf = await loadingTask.promise;

        const page = await pdf.getPage(1);
        const scale = 1.5;
        const viewport = page.getViewport({ scale });

        const outputScale = window.devicePixelRatio || 1;

        const canvas = document.getElementById("the-canvas");
        const context = canvas.getContext("2d");

        canvas.width = Math.floor(viewport.width * outputScale);
        canvas.height = Math.floor(viewport.height * outputScale);
        // canvas.style.width = Math.floor(viewport.width)/2 + "px";
        // canvas.style.height = Math.floor(viewport.height)/2 + "px";
        canvas.style.width = "100%";
        canvas.style.height = "600px";

        const transform =
          outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;

        const renderContext = {
          canvasContext: context,
          transform,
          viewport,
        };
        page.render(renderContext);
      })();
    },
  },
  created() {
    console.log(this.$route.params.res);
    if (this.$route.params.res) {
      this.data = this.$route.params.res;
      this.active =
        this.data.FormAuditFlow.filter((item) => item.state != "").length - 1;
    }
  },
  mounted() {
    this.pdfTypeImg();
  },
};
</script>
<style lang='scss' scoped>
.bdInfo {
  min-height: 100vh;
  padding: 20px;
}
</style>